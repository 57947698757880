import ReadMore from "../components/atoms/ReadMore/read-more";

// GameModeAccordion
import iconEcosystem from "../assets/icons/ecosystem.svg";
import bgGameModeAccordionGameplay from "../assets/backgrounds/gamemode-accordion.jpg";

// BoxCharas
import planetKryo from "../assets/lands/planet-kryo.png";
import planetLuxion from "../assets/lands/planet-luxion.png";
import planetNaya from "../assets/lands/planet-naya.png";

// TeamCore
import AaronB from "../assets/team/core/Aaron-Besnainou.png";
// import MalikT from "../assets/team/core/Malik Tigrine.jpg";
import CarlaB from "../assets/team/core/Carla-Besnainou.png";
import GabinG from "../assets/team/core/Gabin-Guelou.png";
import JeremyT from "../assets/team/core/Jeremy-Trouche.png";
// import JacquesG from "../assets/team/core/Jacques-Garois.jpg";
import GregT from "../assets/team/core/Greg-Taieb.jpg";
import AyaleH from "../assets/team/core/Ayale-Haddad.jpg";
import JordanB from "../assets/team/core/Jordan-Besnainou.jpg";
//import MaximeC from "../assets/team/core/Maxime-carcaillon.jpg";
import JgR from "../assets/team/core/jean-gabriel.jpg";
// import KarimG from "../assets/team/core/Karim-Guenoun-Sanz.jpg";
import FelixR from "../assets/team/core/Felix-Roubaud.jpg";
import AdamR from "../assets/team/core/Adam-rothstein.jpg";
//import LiviaP from "../assets/team/core/Livia-Pilant.png";
import TheoS from "../assets/team/core/Theo-Schmitt.jpg";
//import ThomasC from "../assets/team/core/thomas-crauk.jpg";
import JessyN from "../assets/team/core/jessy-n.jpg";
// import MaximeB from "../assets/team/core/maxime-bucaille.jpg";
//import Maitham from "../assets/team/core/maitham.jpg";
// import RaoulD from "../assets/team/core/raouldes-marest.jpg";

// Advisor & business
import Enzo from "../assets/team/advisors-business/Enzo.jpg";
import NathalieT from "../assets/team/advisors-business/Nathalie T.jpg";
import RomainT from "../assets/team/advisors-business/Romain T.jpg";
import Seth from "../assets/team/advisors-business/Seth.jpg";
// import TheoM from "../assets/team/advisors-business/Theo M.jpg";
import VincentH from "../assets/team/advisors-business/Vincent H.jpg";

// DEV
import DanielN from "../assets/team/dev/Daniel N.jpg";
import FelixLB from "../assets/team/dev/Felix LB.jpg";
import Gabriel from "../assets/team/dev/Gabriel.jpg";
import GregTJ from "../assets/team/dev/Greg TJ.jpg";
import Isham from "../assets/team/dev/Isham.jpg";
import Kevin from "../assets/team/dev/Kevin.jpg";
import Loulou from "../assets/team/dev/Loulou.jpg";
import LucasL from "../assets/team/dev/Lucas L.jpg";
import Lulu from "../assets/team/dev/Lulu.jpg";
import Mika from "../assets/team/dev/Mika.jpg";
import Pierre from "../assets/team/dev/Pierre.jpg";
import Tanguy from "../assets/team/dev/Tanguy.jpg";

// Marketing
import AnuM from "../assets/team/marketing/Anu M.jpg";
import Aurelien from "../assets/team/marketing/Aurélien.jpg";
import AxelC from "../assets/team/marketing/Axel C.jpg";
import AzadT from "../assets/team/marketing/Azad T.jpg";
import BastienH from "../assets/team/marketing/Bastien H.jpg";
import DanielG from "../assets/team/marketing/Daniel G.jpg";
import Keivane from "../assets/team/marketing/Keivane.jpg";
import MartinL from "../assets/team/core/Martin-L.jpg";
import Quentin from "../assets/team/marketing/Quentin.jpg";
import TheoK from "../assets/team/marketing/Théo K.jpg";
import Thomas from "../assets/team/marketing/Thomas.jpg";

// Production
import AlexandreL from "../assets/team/production/Alexandre L.jpg";
import Alexia from "../assets/team/production/Alexia.jpg";
import AlyF from "../assets/team/production/Aly F.jpg";
import GabrielProd from "../assets/team/production/Gabriel.jpg";
import Gaetan from "../assets/team/production/Gaetan.jpg";
import JeanA from "../assets/team/production/Jean A.jpg";
import JessyNProd from "../assets/team/production/Jessica P.jpg";
import JessicaP from "../assets/team/production/Jessica P.jpg";
import LauryG from "../assets/team/production/Laury G.jpg";
import LisaF from "../assets/team/production/Lisa F.jpg";
import LiviaPProd from "../assets/team/production/Livia P.jpg";
import Manon from "../assets/team/production/Manon.jpg";
import MartinJs from "../assets/team/production/Martin JS.jpg";
import MaximeR from "../assets/team/production/Maxime R.jpg";
import MecaniqueG from "../assets/team/production/Mécanique G.jpg";
import PierreG from "../assets/team/production/Pierre G.jpg";
import TheoN from "../assets/team/production/Théo N.jpg";
import ThomasL from "../assets/team/production/Thomas L.jpg";
import ThomasM from "../assets/team/production/Thomas M.jpg";
import ThomasT from "../assets/team/production/Thomas T.jpg";
import TristanC from "../assets/team/production/Tristan C.jpg";
import YannickG from "../assets/team/production/Yannick G.jpg";

// Moderation
import Craft from "../assets/team/modo/Craft.jpg";
import Easy from "../assets/team/modo/Easy.jpg";
import Eqnable from "../assets/team/modo/Eqnable.jpg";
import Joky from "../assets/team/modo/Joky.jpg";
import Krystal2 from "../assets/team/modo/Krystal-2.jpg";
import Krystal from "../assets/team/modo/Krystal.jpg";
import Owbee from "../assets/team/modo/Owbee.jpg";
import ReadyCash from "../assets/team/modo/ReadyCash.jpg";
import YNSeine from "../assets/team/modo/YNSeine.jpg";
import Yoyo from "../assets/team/modo/Yoyo.jpg";

// Writters
import AdamRWritter from "../assets/team/writers/Adam Rothstein.jpg";
import ChristianG from "../assets/team/writers/Christian G.jpg";
import NoemieH from "../assets/team/writers/Noémie H.jpg";
import RichardH from "../assets/team/writers/Richard H.jpg";
import { CODE_EPIC } from "./code";

export const textHome = {
  title: "Hunters, The Mine\nAwaits you",
  code: `Early-Access Alpha Epic Code: ${CODE_EPIC}`,
  mexc: "Sign trough this link and KYC to participate to the 50,000 USDT Airdrops: https://www.mexc.com/fr-FR/register?inviteCode=mexc-1z6vv",
  details:
    "Prepare to venture deep into an abandoned mine turned nightmarish labyrinth, shrouded in darkness and crawling with hostile Kapras.\n\nCollect valuable resources and blueprints, but beware… your greed may lead you to greater risks than rewards.",
  textIntroduction: (
    <ul style={{ paddingLeft: "1.5rem" }}>
      {/*
      <li>
        Road To Genesis Alpha Early Access from{" "}
        <span style={{ fontWeight: 600 }}>4th to January 5th at 4 PM CET</span>
      </li>
      <li>TGE Date: 19/12/2024 on Uniswap & MEXC</li>
      */}
      <li>
        <a href="https://quest.oxyaorigin.com" target="_blank" rel="noreferrer">
          Hunter Program Chapter 2
        </a>
      </li>
      <li>
        RTG IS NOW LIVE until February 17th! Early Access Alpha Epic Code:{" "}
        {CODE_EPIC}
      </li>
      <li>
        <a
          href="https://medium.com/@OxyaOriginOfficial/oxya-origin-road-to-genesis-early-alpha-access-token-launch-headquarters-launch-8cb0ac4760a4"
          target="_blank"
          rel="noreferrer"
        >
          Latest update
        </a>
      </li>
    </ul>
  ),
  rtgDetails: {
    loreTexts: [
      "Biome Laboratory",
      "Biome Industrial",
      "Biome Mines",
      "Biome Ancient Ruines",
    ],
    procedural: {
      title: "4 Different Biomes\n+60 Unique Rooms",
      details: null,
    },
    loot: {
      title: "Play, Loot & Extract",
      details: (
        <ul style={{ paddingLeft: "1.5rem" }}>
          <li>
            <span style={{ fontWeight: 600 }}>Play</span> with your teammates
          </li>
          <li>
            <span style={{ fontWeight: 600 }}>Collect</span> resources
          </li>
          <li>
            <span style={{ fontWeight: 600 }}>Survive</span> the dungeon
          </li>
          <li>
            <span style={{ fontWeight: 600 }}>Extract</span> - Craft - Repeat
          </li>
        </ul>
      ),
    },
  },
};

export const textEcosystem = {
  rtg: {
    title: "Gaming Mechanism",
    details: (
      <span>
        Face waves of enemies and survive solo or with friends, extract
        resources and push your luck with a "double or nothing" risk.
        <br />
        <br />
        Craft and upgrade weapons and equipment.
        <br />
        <br />
        <span style={{ fontWeight: 600 }}>
          Every decision, every gamble, comes with consequences.
        </span>
      </span>
    ),
  },
  hq: {
    title: "HQ Mechanism",
    details: (
      <span>
        Strategic management game where you craft buildings, mint and manage
        collectibles such as cosmetic wearables and gear, that will be usable
        across multiple titles within the Oxya universe.
        <br />
        <br />
        HQ will bring early utility to the Oxyz token.
        <br />
        <br />
        <span style={{ fontWeight: 600 }}>
          Shape the ecosystem to your advantage and strategically redirect
          valuable resources your way.
        </span>
      </span>
    ),
  },
};

export const textGameModes = [
  {
    key: 1,
    defaultExpanded: true,
    title: "Road To Genesis",
    textBtnPlay: "Oxya Alpha Gameplay",
    details: (
      <span>
        Third-person shooter that blends intense, tactical combat with rich
        dungeon exploration, challenging you with every Rundown of a high-stake
        extraction looter.
      </span>
    ),
    textBtnActionOne: "Play Now",
    hrefIconBtnActionOne: iconEcosystem,
    hrefBackgroundBtn: bgGameModeAccordionGameplay,
    disabledOne: false,
    disabledTwo: false,
    hashLinkOne: "/#avatars",
    onClickOne: () => () => {
      window.open(whitelistUrl);
    },
    onClickTwo: () => () => {},
    onClickVideo: (setStateTitle, setStateLink, setOpenModal) => () => {
      setStateTitle("Oxya Gameplay");
      setStateLink("https://www.youtube.com/embed/fSP3Q_sOsMI");
      setOpenModal(true);
    },
  },
  {
    key: 2,
    defaultExpanded: false,
    title: "HeadQuarters",
    textBtnPlay: null,
    details: (
      <span>
        The HeadQuarters is where players will be able to{" "}
        <span style={{ fontWeight: 600 }}>craft and upgrade in-game items</span>
        , complete missions and strategically manage Oxya Origin assets to
        generate active or passive revenue.
      </span>
    ),
    textBtnActionOne: "Opeansea Lands",
    hrefIconBtnActionOne: iconEcosystem,
    textBtnActionTwo: "Our ecosystem",
    hrefIconBtnActionTwo: iconEcosystem,
    hrefBackgroundBtn: bgGameModeAccordionGameplay,
    disabledOne: false,
    disabledTwo: false,
    hashLinkOne: "/#lands",
    onClickOne: () => () => {},
    onClickTwo: (history) => () => {
      history.push("/ecosystem");
    },
    onClickVideo: () => () => {},
  },
  {
    key: 3,
    defaultExpanded: false,
    title: "Gatemap",
    textBtnPlay: null,
    details:
      "Explore the Oxya Origin universe by visiting the Gatemap to connect with other players, participate in treasure hunts and complete Questgiver missions from our partners to earn engagement rewards and airdrops.",
    textBtnActionOne: "Opeansea Lands",
    hrefIconBtnActionOne: iconEcosystem,
    textBtnActionTwo: "Visit Gatemap",
    hrefIconBtnActionTwo: iconEcosystem,
    hrefBackgroundBtn: null,
    disabledOne: false,
    disabledTwo: false,
    hashLinkOne: "/#lands",
    onClickOne: () => () => {},
    onClickTwo: () => () => {
      window.open("https://gatemap.oxyaorigin.com", "_blank");
    },
    onClickVideo: (setStateTitle, setStateLink, setOpenModal) => () => {},
  },
];

export const textBoxCharas = [
  {
    key: 1,
    hrefPlanet: planetKryo,
    title: "Vaalbara",
    color: "rgb(15, 190, 255)",
    colorTextShadow: "rgba(15, 190, 255, 0.7)",
    subTitle: "Rulers of Kryo",
    details: (
      <ReadMore style={{ marginTop: "1rem", marginLeft: "1.5rem" }}>
        <p
          style={{
            maxWidth: "95%",
            fontSize: "1.6rem",
            textAlign: "justify",
            marginBottom: "1rem",
          }}
        >
          Residing at the top of the financial pyramid, the Vaalbara have no
          need to train in combat as they can afford the best technology,
          mercenaries and defence systems to protect them and their financial
          interests.
        </p>
      </ReadMore>
    ),
  },
  {
    key: 2,
    hrefPlanet: planetLuxion,
    title: "Niffelheim",
    color: "rgb(186,23,0)",
    colorTextShadow: "rgba(186,23,0, 0.7)",
    subTitle: "The Luxion Warriors",
    details: (
      <p
        style={{
          fontSize: "1.6rem",
          textAlign: "justify",
          maxWidth: "95%",
        }}
      >
        The Niffelheim strike fear into all who oppose them. Clad in
        indestructible Steelvar armor, they are a relentless force on the
        battlefield, merciless and unyielding. Their very presence is a
        harbinger of doom, embodying the might of Luxion.
      </p>
    ),
  },
  {
    key: 3,
    hrefPlanet: planetNaya,
    title: "Anokye",
    color: "rgb(197,123,19)",
    colorTextShadow: "rgba(197,123,19, 0.7)",
    subTitle: "The Fighters of Naya",
    details: (
      <ReadMore style={{ marginTop: "1rem", marginLeft: "1.5rem" }}>
        <p
          style={{
            maxWidth: "95%",
            fontSize: "1.6rem",
            textAlign: "justify",
          }}
        >
          Fast, stealthy, and agile, the Anokye excel at disrupting the field of
          combat with precision and speed, overwhelming their enemies before
          they can react.
        </p>
      </ReadMore>
    ),
  },
];

export const teams = [
  {
    title: "Core team",
    folder: "core",
    defaultExpanded: true,
    key: 1,
    members: [
      {
        name: "Aaron Besnainou",
        pic: AaronB,
        role: "COO & President",
        lk: "",
        bio: `Business strategist, in crypto since 2018. Previously co-founded multiple startups totaling 10M$ in sales across commercial FinTech & PropTech. Alumni of University of Michigan, ESSEC Business School & CASS Business School. Aaron’s strategic vision, his strong knowledge about the NFT markets and networking skills add tremendous value to Oxya Origin.`,
      },
      {
        name: "Carla Besnainou",
        pic: CarlaB,
        role: "Co-CEO",
        lk: "",
        bio: `Previously HR and COO for 7+ years, she has learnt how to deal with more than 200 people and drive a company to profit quickly. On top of that, she has a great experience in the real estate sector and managing artists through entrepreneurial experiences around digital art and her education at ESA, a leading Architectural School in Paris.`,
      },
      {
        name: "Gabin Guélou",
        pic: GabinG,
        role: "Co-CEO",
        lk: "",
        bio: `PhD in Materials Chemistry, boasts a rich scientific background cultivated over five years in national research institutes dedicated to renewable energies. Transitioning from academia, he delved into the gaming industry, where he utilized his scientific prowess to analyze and organize research on blockchain-based games for investment purposes. Now, at Oxya, he harnesses his leadership, organizational skills, and insightful understanding of blockchain and gaming to drive forward initiatives.`,
      },
      {
        name: "Jeremy Trouche",
        pic: JeremyT,
        role: "CFO",
        lk: "",
        bio: `After 6+ years as a Sales Trader in major international financial institutions, he transitioned into Web3 working as Head of Finance and Head of Sales for 3y at Ternoa, the largest French blockchain infrastructure dedicated to NFTs. His traditional & Web3 cross asset financial experience combined with his knowledge of the blockchain and NFT global ecosystems are a perfect fit for Oxya Origin.`,
      },
      {
        name: "Greg Taieb",
        pic: GregT,
        role: "CLO",
        lk: "",
        bio: `Left his successful real estate businesses with more than 200 deals/year and 60 people to join his life goal of building something innovative and unique. Greg is a serial entrepreneurs and investisors with extensive experiences and connections to make a sustainable game.`,
      },
      {
        name: "Ayale Haddad",
        pic: AyaleH,
        role: "CTO",
        lk: "",
        bio: `Built his first video game at 14, holds multiple degrees in Machine Learning, Mathematics & CompSci & Previously Lead Dev at AirMask, a unique blockchain wallet, Ayale brings his expertise of Web2 & Web3 tools to the Team. As an early NFT collector, Ayale also helps the team by providing precious insights about the latest trends of the NFT market.`,
      },
      /*{
        name: "Karim Guenoun Sanz",
        pic: KarimG,
        role: "Co-CTO (Gaming)",
        lk: "",
        bio: `Karim has extensive experience producing V.R apps on Unreal Engine and has an optimization expertise on UE4/5. He has already worked with Sanofi, PSA, RATP and Carrefour including his work at the VivaTechnology in Paris. Karim also has a background in sound and music, working with famous events and well known artists. One of his notable achievements is the Playstation Award granted in Spain.`,
      },*/
      {
        name: "Jordan Geynosi",
        pic: JordanB,
        role: "Board Member",
        lk: "",
        bio: `Creative writer & screenwriter, Science Fiction & Political-Economical Transformation Content,  crypto advisor, business strategist for +10 years.\nHis experiences as a web3 entrepreneur, writer & drama director are artistically & politically game changing.`,
      },
      {
        name: "Adam Rothstein",
        pic: AdamR,
        role: "Head of Product",
        lk: "",
        bio: `Passionate about gaming and cryptocurrencies since 2018. Adam studied at La Sorbonne and went into the world of web3 after a long stint at the Institute of Urbanism in Paris. Project advisor on many NFTs projects and organizer of e-sport tournaments since 2021, he joined our ranks in February 2022 as product manager.`,
      },
      {
        name: "Theo Schmitt",
        pic: TheoS,
        role: "Head of Marketing",
        lk: "",
        bio: "Entrepreneur since a young age, he has created physical and digital businesses in many different fields but the internet world and video games in particular are his playfield. He started investing in crypto in 2017. His previous experiences in marketing, communication and in crypto has naturally led him to join Oxya which is a project bringing together his passions.",
      },
      {
        name: "Martin Liduena",
        pic: MartinL,
        role: "Head of Partnerships",
        lk: "",
        bio: "Serial entrepreneur Martin has multiple years of experience in business development and international relationship management having worked with top brands including Unilever and l’Oreal before transitioning into a full time Web3 degenerate.",
      },
      {
        name: "Daniel Georr",
        pic: DanielG,
        role: "Head of Staff",
        lk: "",
        bio: "Daniel has extensive experience working in advertising agencies and international startup NGOs as a strategist, project manager and copywriter. He joined Oxya Origin with the ambition to make Web3 gaming accessible to the masses.",
      },
      /*{
        name: "Maxime Carcaillon",
        pic: MaximeC,
        role: "Lead Environmental",
        lk: "",
        bio: `Maxime has been working in the game industry for 5+ years. He previously worked as Level Artist for a AAA production of Ubisoft, The Division 2, and then, motivated by his passion for the Zelda series, he conceived and developed his own game all by himself. Over his career he participated in the conception, development, and publication of 3 different studio games, with different production methods and graphical styles. His polyvalence, technical skills, creativity, and unfailing good mood make him a delight to work with.`,
      },*/
      {
        name: "Jean Gabriel Rego-Kirk",
        pic: JgR,
        role: "Game Designer",
        lk: "",
        bio: `J.G. has been passionate about games since early childhood and can count on his experience and knowledge of hundreds (if not thousands) of different games to find inspiration for his game design. Before working on video games, Djidjay has created big outdoor complex games and serious games. He also developed PC-games for hardcore gamers, free-to-play casual mobile games, VR, and smartwatch games. He loves to learn, thinks creatively, and considers new constraints and features brought by blockchain in gaming as both opportunities and challenges making his job even more interesting.`,
      },
      {
        name: "Felix Roubaud",
        pic: FelixR,
        role: "Full Stack Dev",
        lk: "",
        bio: `Before joining Oxya Origin, Felix has carried out numerous missions with Parisian tech startups, including Ankorstore, Cajoo & Murfy. During these missions, he developed several technical hats; software engineer (React / Node / Python), data engineer (Stitch / DBT / bigQuery) and architect engineer (GCP / Kubernetes / Serverless). Deeply interested in blockchain, he also codes in solidity and builds web3 products.`,
      },
      /*{
        name: "Livia Pilant",
        pic: LiviaP,
        role: "Character Artist",
        lk: "",
        bio: "After having spent 1 year in applied arts at the MANA institute at Itecom Art Design and one year in a graphic design school, I integrated the Newdge institute for a 5 year long period during which I earned my masters in Game Art. I then specialised myself as a character artist and more specifically in creature design in a realistic style.",
      },
      {
        name: "Thomas Crauk",
        pic: ThomasC,
        role: "Lead Writer",
        lk: "",
        bio: `Former script writer at BackLight, awarded for Eclipse VR experience, Thomas brings his 20+ years of experience as Artistic Director and screen writer in the movie industry, to Oxya Origin. His talent and passion for storytelling combined with his deep culture and genuine curiosity helped create a whole fictional world which is the backbone of our game ecosystem.`,
      },*/
      {
        name: "Jessy N",
        pic: JessyN,
        role: "Lead Programmer",
        lk: "",
        bio: "I have always loved solving puzzles and challenges in video games. After studies in programming and a training in video games, I specialized in multiplayer video games, metavers and virtual reality. At Oxya, I manage the technical development of projects made on Unreal Engine 5.",
      },
      /*{
        name: "Maxime Bucaille",
        pic: MaximeB,
        role: "Advisor",
        lk: "",
        bio: `Maxime has been working in the blockchain industry since 2017. He helped shape DeFi in its early days building one of the first Decentralized Exchanges (AirSwap) and then went on to develop the blockchain team at Atari where he laid the foundations for their Web3 initiative. Maxime is currently a Web3 VC at QCP Capital where he focuses mainly on DeFi and Gaming.`,
      },
      {
        name: "Maitham",
        pic: Maitham,
        role: "Advisor",
        lk: "",
        bio: `Co-Founder & CEO of Material10, an AAA blockchain gaming studio and publisher building a new paradigm in gaming backed by leading investors in the space. Prev DAO Lead at Nyan Heroes, one of the leading AAA blockchain game studios. Also was Ops & Incubator Lead at OlympusDAO, growing it and building the next iteration of DeFi. Prior to entering the blockchain world, worked at FAANG as a Product Manager at Amazon within Machine Learning teams along with experiences across venture capital, investment banking and hedge funds. Also currently, a consultant to Fortune500 companies on blockchain infrastructure, DeFi & more.`,
      },
      {
        name: "Raoul Desmarest",
        pic: RaoulD,
        role: "Advisor Lead Tech",
        lk: "",
        bio: `Before joining Oxya Origin, Raoul has been Technical Artist/VFX Artist at Sloclap, working on SIFU for 2 and a half years. He also teaches Unreal Engine basics @ New3dge, a French game dev. school. Raoul is a “game purist” with tremendous knowledge about games and their history. His experience and passion help him provide guidance to, and grow the skills of, our junior developers and artists.`,
      },*/
      // { name : "Martin L", pic : "Martin-L.jpg", role : "", lk : "", bio : "" },
    ],
  },
  {
    title: "Adivsors & Business",
    folder: "advisors-business",
    defaultExpanded: false,
    key: 2,
    members: [
      { name: "Enzo", pic: Enzo, role: "", lk: "", bio: "" },
      {
        name: "Nathalie T",
        pic: NathalieT,
        role: "",
        lk: "",
        bio: "",
      },
      /*{
                      name: "Nicolas Bougartchev",
                      pic: "Nicolas Bougartchev.jpg",
                      role: "",
                      lk: "",
                      bio: "",
                    },
      {
        name: "Nicolas C",
        pic: NicolasC,
        role: "",
        lk: "",
        bio: "",
      },*/
      {
        name: "Romain T",
        pic: RomainT,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Seth", pic: Seth, role: "", lk: "", bio: "" },
      /*{ name: "Theo M", pic: TheoM, role: "", lk: "", bio: "" },*/
      {
        name: "Vincent H",
        pic: VincentH,
        role: "",
        lk: "",
        bio: "",
      },
    ],
  },
  {
    title: "Dev",
    folder: "dev",
    key: 3,
    defaultExpanded: false,
    members: [
      {
        name: "Daniel N",
        pic: DanielN,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Felix LB",
        pic: FelixLB,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Gabriel",
        pic: Gabriel,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Greg TJ",
        pic: GregTJ,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Isham", pic: Isham, role: "", lk: "", bio: "" },
      { name: "Kevin", pic: Kevin, role: "", lk: "", bio: "" },
      { name: "Loulou", pic: Loulou, role: "", lk: "", bio: "" },
      {
        name: "Lucas L",
        pic: LucasL,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Lulu", pic: Lulu, role: "", lk: "", bio: "" },
      { name: "Mika", pic: Mika, role: "", lk: "", bio: "" },
      { name: "Pierre", pic: Pierre, role: "", lk: "", bio: "" },
      { name: "Tanguy", pic: Tanguy, role: "", lk: "", bio: "" },
    ],
  },
  {
    title: "Marketing",
    folder: "marketing",
    key: 4,
    defaultExpanded: false,
    members: [
      { name: "Anu M", pic: AnuM, role: "", lk: "", bio: "" },
      {
        name: "Aurélien",
        pic: Aurelien,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Axel C", pic: AxelC, role: "", lk: "", bio: "" },
      { name: "Azad T", pic: AzadT, role: "", lk: "", bio: "" },
      {
        name: "Bastien H",
        pic: BastienH,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Keivane",
        pic: Keivane,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Quentin",
        pic: Quentin,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Théo K", pic: TheoK, role: "", lk: "", bio: "" },
      { name: "Thomas", pic: Thomas, role: "", lk: "", bio: "" },
      /*{ name: "misc-1", pic: "misc-1.jpg", role: "", lk: "", bio: "" },
                    { name: "misc-2", pic: "misc-2.jpg", role: "", lk: "", bio: "" },
                    { name: "misc-3", pic: "misc-3.jpg", role: "", lk: "", bio: "" },
                    { name: "misc-4", pic: "misc-4.jpg", role: "", lk: "", bio: "" },
                    { name: "misc-5", pic: "misc-5.jpg", role: "", lk: "", bio: "" },*/
    ],
  },
  {
    title: "Production",
    folder: "production",
    key: 5,
    defaultExpanded: false,
    members: [
      {
        name: "Alexandre L",
        pic: AlexandreL,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Alexia", pic: Alexia, role: "", lk: "", bio: "" },
      { name: "Aly F", pic: AlyF, role: "", lk: "", bio: "" },
      {
        name: "Gabriel",
        pic: GabrielProd,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Gaetan", pic: Gaetan, role: "", lk: "", bio: "" },
      { name: "Jean A", pic: JeanA, role: "", lk: "", bio: "" },
      {
        name: "Jessica P",
        pic: JessicaP,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Jessy N",
        pic: JessyNProd,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Laury G",
        pic: LauryG,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Lisa F", pic: LisaF, role: "", lk: "", bio: "" },
      {
        name: "Livia P",
        pic: LiviaPProd,
        bio: "",
        role: "",
        lk: "",
      },
      { name: "Manon", pic: Manon, role: "", lk: "", bio: "" },
      {
        name: "Martin JS",
        pic: MartinJs,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Maxime R",
        pic: MaximeR,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Mécanique G",
        pic: MecaniqueG,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Pierre G",
        pic: PierreG,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Théo N", pic: TheoN, role: "", lk: "", bio: "" },
      {
        name: "Thomas L",
        pic: ThomasL,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Thomas M",
        pic: ThomasM,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Thomas T",
        pic: ThomasT,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Tristan C",
        pic: TristanC,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Yannick G",
        pic: YannickG,
        role: "",
        lk: "",
        bio: "",
      },
      //{ name: "misc-1", pic: "misc-1.jpg", role: "", lk: "", bio: "" },
    ],
  },
  {
    title: "Moderation",
    folder: "modo",
    key: 6,
    defaultExpanded: false,
    members: [
      { name: "Craft", pic: Craft, role: "", lk: "", bio: "" },
      { name: "Easy", pic: Easy, role: "", lk: "", bio: "" },
      {
        name: "Eqnable",
        pic: Eqnable,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Joky", pic: Joky, role: "", lk: "", bio: "" },
      {
        name: "Krystal-2",
        pic: Krystal2,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Krystal",
        pic: Krystal,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Owbee", pic: Owbee, role: "", lk: "", bio: "" },
      {
        name: "ReadyCash",
        pic: ReadyCash,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "YNSeine",
        pic: YNSeine,
        role: "",
        lk: "",
        bio: "",
      },
      { name: "Yoyo", pic: Yoyo, role: "", lk: "", bio: "" },
    ],
  },
  {
    title: "Writers",
    folder: "writers",
    key: 7,
    defaultExpanded: false,
    members: [
      {
        name: "Adam Rothstein",
        pic: AdamRWritter,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Christian Guenot",
        pic: ChristianG,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Noémie H",
        pic: NoemieH,
        role: "",
        lk: "",
        bio: "",
      },
      {
        name: "Richard H",
        pic: RichardH,
        role: "",
        lk: "",
        bio: "",
      },
      /*{ name: "misc-1", pic: "misc-1.jpg", role: "", lk: "", bio: "" },
                    { name: "misc-2", pic: "misc-2.jpg", role: "", lk: "", bio: "" },
                    { name: "misc-3", pic: "misc-3.jpg", role: "", lk: "", bio: "" },
                    { name: "misc-4", pic: "misc-4.jpg", role: "", lk: "", bio: "" },*/
    ],
  },
];

export const textModalRegister = {
  title: "Registration\nSuccess!",
  details: "Welcome to the Oxya Origin army, the Road to Genesis awaits!",
  listDetails: [
    {
      key: 1,
      text: "Complete and verify your account to qualify for a Starter Pack (the email may be in your spam folder).",
    },
    {
      key: 2,
      text: "Follow us on socials to stay up to date with the latest news.",
    },
  ],
};

export const appUrl = "https://app.oxyaorigin.com";
export const whitelistUrl =
  "https://store.epicgames.com/p/oxya-origin-road-to-genesis-6ed2a9";
